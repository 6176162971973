@tailwind base;
@tailwind components;
@tailwind utilities;


*:disabled {
    opacity: 0.7;
}

body::after {
    background-image: url('/assets/bg-small.jpg');
    background-size: cover;
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.1;
    z-index: -1;
}

@media screen and (min-width: 768px) {
    body::after {
        background-image: url('/assets/bg-large.jpg');
    }
}